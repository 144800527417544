import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/inputs/CustomInput";
import { GENDER, USER_ROLES } from "../../utils/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  calculateAge,
  findNameByRole,
  getAllowedRoles,
  titleCase,
} from "../../utils/helper";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import {
  IMPAIREMENT_TYPE,
  LEVEL_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS,
} from "../../utils/patient.constants";
import ModuleContainer from "../../components/layouts/common/ModuleContainer";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SubmitButton from "../../components/button/SubmitButton";
import AudioGram from "./AudioGram";
import { useTheme, styled } from "@mui/material";
import COUNTRY_CODES from "../../utils/CountryCode";
import SelectDeviceui from "./SelectDeviceui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getUserApi } from "../../apis/user.api";

const CreateUi = ({
  value,
  setValue,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  useEffect(() => {
    console.log("value", value);
  }, [value]);

  const isDeviceSelect =
    (fields.right_fitted_device.device_tube &&
      fields.right_fitted_device.dom_type) ||
    (fields.left_fitted_device.device_tube &&
      fields.left_fitted_device.dom_type)
      ? true
      : false;

  const isAudioGranSelect =
    (fields.lf2 &&
      fields.lf3 &&
      fields.lf4 &&
      fields.lf5 &&
      fields.lf6 &&
      fields.lf7 &&
      fields.lf8) ||
    (fields.rf2 &&
      fields.rf3 &&
      fields.rf4 &&
      fields.rf5 &&
      fields.rf6 &&
      fields.rf7 &&
      fields.rf8)
      ? true
      : false;

  const { user } = useSelector((state) => state);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isAgeModified, setIsAgeModified] = useState(false);
  useEffect(() => {
    if (isAgeModified) {
      let timeOut = setTimeout(() => {
        if (
          fields.age !== null &&
          fields.age !== undefined &&
          !isNaN(fields.age) &&
          fields.age !== 0
        ) {
          const dob = moment()
            .set("date", 1)
            .set("month", 5)
            .subtract(fields.age, "years");

          if (dob.isValid()) {
            setFields((prevFields) => ({
              ...prevFields,
              err: "",
              dob: dob.toISOString(),
            }));
          } else {
            setFields((prevFields) => ({
              ...prevFields,
              err: "Invalid date of birth",
            }));
          }
        }
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [fields.age, isAgeModified]);

  const navigate = useNavigate();
  const params = useParams();
  const letter = (name) => {
    let newName = name?.charAt(0).toUpperCase();
    let flag = false;
    for (let i = 1; i < name?.length; i++) {
      if (name?.charAt(i) == " ") {
        flag = true;
      } else {
        if (flag) {
          newName = newName + " " + name?.charAt(i).toUpperCase();
          flag = false;
        } else {
          newName = newName + name?.charAt(i).toLowerCase();
        }
      }
    }
    return newName;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          overflowY: "hidden",

          flex: 1,
          borderRadius: "10px",
          marginTop: "10px",
          overflow: "hidden",
        }}
      >
        {/* <ModuleContainer
            containerProps={{
                component: "form",
                onSubmit
            }}
            loading={loading}
            err={fields.err}

        > */}

        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flex: 1,
              flexDirection: "column",
              overflow: "auto",
              scrollbarWidth: "none  ",
            }}
          >
            {params?.id && (
              <Typography ml={1} color={"#2D3B67"} variant="h3">
                <IconButton
                  sx={{ marginRight: "5px" }}
                  onClick={() => {
                    navigate(`/patient-details/${params.id}`);
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#2D3B67" }} fontSize="medium" />
                </IconButton>

                {fields?.first_name?.charAt(0).toUpperCase() +
                  fields?.first_name?.slice(1).toLowerCase()}
              </Typography>
            )}
            {
              <>
                {value == 0 && (
                  <Grid container>
                    <Grid
                      item
                      xs={8}
                      md={6}
                      sx={{ gap: "32px", marginLeft: "48px", height: "82vh" }}
                    >
                      <Typography color="error">{fields.err}</Typography>

                      <Box sx={{ display: "flex", width: "640px" }} mt={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <CustomInput
                              autoFocus={true}
                              disabled={loading}
                              value={fields.first_name}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  err: "",
                                  first_name: e.target.value,
                                })
                              }
                              type="text"
                              label={"Full Name*"}
                            />
                          </Grid>
                          <Grid item xs={6} md={6} sx={{}}>
                            {/* <DesktopDatePicker
                              disableFuture
                              inputFormat="DD/MM/yyyy"
                              renderInput={(props) => (
                                <CustomInput {...props} />
                              )}
                              autoFocus={true}
                              disabled={loading}
                              value={moment(fields.dob)}
                              onChange={(val) => {
                                if (val != null) {
                                  const isoString = val.toISOString();
                                  setFields({
                                    ...fields,
                                    err: "",
                                    dob: isoString,
                                  });
                                }
                              }}
                              label="Date of Birth*"
                            /> */}
                            <DesktopDatePicker
                              disableFuture
                              inputFormat="DD/MM/yyyy"
                              renderInput={(props) => (
                                <CustomInput {...props} />
                              )}
                              autoFocus={true}
                              disabled={loading}
                              value={fields.dob ? moment(fields.dob) : null}
                              onChange={(val) => {
                                if (val && val.isValid()) {
                                  const isoString = val.toISOString();
                                  setFields({
                                    ...fields,
                                    err: "",
                                    dob: isoString,
                                    age: calculateAge(isoString),
                                  });
                                  setIsAgeModified(false); // Ensure further age modifications don't interfere
                                } else {
                                  setFields({
                                    ...fields,
                                    err: "",
                                    dob: "",
                                    age: 0,
                                  });
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ height: "100%", width: "100%" }}>
                              {/* <CenteredBox sx={{ height: "100%", width: "100%", background: "#f2f2f2", border: "1px solid " + theme.palette.primary.main }} p={2} >
                                                    Age:   {calculateAge(fields.dob)} years
                                                </CenteredBox> */}

                              <CustomInput
                                disabled={loading}
                                value={fields.age}
                                onChange={(e) => {
                                  // const dob = moment().set('date',1).set('month',5).add(-1*e.target.value,'year')
                                  setFields({ ...fields, age: e.target.value });
                                }}
                                onBlur={(e) => {
                                  const dob = moment()
                                    .set("date", 1)
                                    .set("month", 5)
                                    .add(-1 * e.target.value, "year");
                                  setFields({
                                    ...fields,
                                    err: "",
                                    dob: dob.toISOString(),
                                  });
                                }}
                                type="number"
                                label={"Age*"}
                              />
                            </Box>
                          </Grid>
                          {/* <Grid item xs={6} md={4} >
                                            <CustomInput

                                                disabled={loading}
                                                value={fields.middle_name}
                                                onChange={(e) => setFields({ ...fields, err: '', middle_name: e.target.value })}
                                                type="text"
                                                label={"Middle Name"}

                                            />

                                        </Grid>
                                        <Grid item xs={6} md={4} >
                                            <CustomInput

                                                disabled={loading}
                                                value={fields.last_name}
                                                onChange={(e) => setFields({ ...fields, err: '', last_name: e.target.value })}
                                                type="text"
                                                label={"Last Name"}

                                            />

                                        </Grid> */}

                          <Grid item xs={6} md={6}>
                            <CustomInput
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Select
                                      disableUnderline
                                      value={fields.country_code}
                                      onChange={(e) =>
                                        setFields({
                                          ...fields,
                                          err: "",
                                          country_code: e.target.value,
                                        })
                                      }
                                      variant="standard"
                                      size="small"
                                    >
                                      {COUNTRY_CODES.map((item) => (
                                        <MenuItem
                                          key={item.code}
                                          value={item.dial_code}
                                        >
                                          {item.code}({item.dial_code})
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </InputAdornment>
                                ),
                              }}
                              disabled={loading}
                              value={fields.phone}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  err: "",
                                  phone: e.target.value,
                                })
                              }
                              type="number"
                              label={"Phone No.*"}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomInput
                              disabled={loading}
                              value={fields.email}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  err: "",
                                  email: e.target.value,
                                })
                              }
                              type="email"
                              label={"Email"}
                            />
                          </Grid>
                          {/* <Grid item xs={4} > */}

                          {/* <Box sx={{ height: "100%", width: "100%" }}  > */}
                          {/* {/* <CenteredBox sx={{ height: "100%", width: "100%", background: "#f2f2f2", border: "1px solid " + theme.palette.primary.main }} p={2} > */}
                          {/* Age:   {calculateAge(fields.dob)} years */}
                          {/* </CenteredBox> */}

                          {/* <CustomInput */}

                          {/* disabled={loading} */}
                          {/* value={calculateAge(fields.dob)} */}
                          {/* onChange={(e) => { */}
                          {/* const dob = moment().set('date', 1).set('month', 5).add(-1 * e.target.value, 'year') */}
                          {/* setFields({ ...fields, err: '', dob: dob.toISOString() }) */}
                          {/* }} */}
                          {/* type="number" */}
                          {/* label={"Age*"} */}

                          {/* /> */}
                          {/* </Box> */}

                          {/* </Grid>  */}
                          <Grid
                            item
                            xs={12}
                            sx={{
                              width: "1280px",
                              flexshrink: "0",
                            }}
                          >
                            <CustomInput
                              multiline
                              rows={2}
                              disabled={loading}
                              value={fields.address}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  err: "",
                                  address: e.target.value,
                                })
                              }
                              type="address"
                              label={"Address*"}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            md={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignitems: "center",
                              paddingLeft: "16px",
                              gap: "10px",
                            }}
                          >
                            <CustomInput
                              disabled={loading}
                              value={fields.zip_code}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  err: "",
                                  zip_code: e.target.value,
                                })
                              }
                              type="number"
                              label={"ZIP/Postal*"}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            md={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignitems: "center",
                              gap: "10px",
                              paddingLeft: "16px",
                            }}
                          >
                            <CustomInput
                              disabled={loading}
                              value={fields.state}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  err: "",
                                  state: e.target.value,
                                })
                              }
                              type="text"
                              label={"State*"}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            md={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignitems: "center",
                              gap: "10px",
                              paddingLeft: "16px",
                            }}
                          >
                            <CustomInput
                              disabled={loading}
                              value={fields.city}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  err: "",
                                  city: e.target.value,
                                })
                              }
                              type="text"
                              label={"City*"}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {user.data.role === USER_ROLES.ADMIN && (
                              <AsyncDropDown
                                InputComponent={(props) => (
                                  <CustomInput
                                    label
                                    placeholder="Select Audiologist"
                                    {...props}
                                  />
                                )}
                                lazyFun={async (props) => {
                                  return await getUserApi({
                                    ...props,
                                    role: USER_ROLES.AUDIO_LOGIST,
                                  });
                                }}
                                OptionComponent={({ option, ...rest }) => {
                                  return (
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      {...rest}
                                    >
                                      <Typography ml={3} variant="h5">
                                        {option.name}
                                      </Typography>
                                    </Box>
                                  );
                                }}
                                onChange={async (changedVal) => {
                                  setFields({
                                    ...fields,
                                    err: "",
                                    assigned: changedVal?._id,
                                  });
                                }}
                                titleKey={"name"}
                                valueKey={"_id"}
                              />
                            )}
                          </Grid>
                          <Grid
                            sx={{
                              border: "1px solid navy ",
                              borderRadius: "5px",
                              marginLeft: "8px",
                              marginTop: "16px",
                              width: "400px",
                              height: "45px",
                              position: "relative",
                            }}
                          >
                            {/* <Grid item xs={6} md={6} sx={{ marginTop: "10px", marginLeft: "10px" }}> */}
                            <Grid
                              sx={{
                                marginTop: "8px",
                                marginLeft: "8px",
                                position: "absolute",
                              }}
                            >
                              <FormLabel
                                disabled={loading}
                                id="demo-row-radio-buttons-group-label"
                              >
                                Gender*
                              </FormLabel>
                              {/* <FormControl sx={{ position: "absolute", marginLeft: "90px", width: "500px", height: "70px", }}> */}
                              <Box
                                sx={{
                                  position: "absolute",
                                  marginLeft: "73px",
                                  top: "0px",
                                  width: "1px",
                                  height: "30px",
                                  backgroundColor: "navy",
                                }}
                              ></Box>
                              {/* <LeftSideBox /> */}
                              <RadioGroup
                                sx={{
                                  position: "absolute",
                                  width: "400px",
                                  top: "-6px",
                                  left: "90px",
                                }}
                                value={fields.gender}
                                onChange={(e) =>
                                  setFields({
                                    ...fields,
                                    err: "",
                                    gender: e.target.value,
                                  })
                                }
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                              >
                                {Object.keys(GENDER).map((item) => (
                                  <FormControlLabel
                                    disabled={loading}
                                    key={item}
                                    value={GENDER[item]}
                                    control={<Radio />}
                                    label={item}
                                  />
                                ))}
                              </RadioGroup>
                              {/* </FormControl> */}
                            </Grid>

                            {/* </Grid> */}
                          </Grid>
                          {/* <Grid item xs={7} >
                                            <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Gender*</FormLabel>
                                            <RadioGroup
                                            value={fields.gender}
                                            onChange={(e) => setFields({ ...fields, err: '', gender: e.target.value })}
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >
                                                    {Object.keys(GENDER).map((item) => <FormControlLabel key={item} value={GENDER[item]} control={<Radio />} label={item} />)}


                                                </RadioGroup>
                                            </FormControl>
                                        </Grid> */}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                  </Grid>
                )}
                {value == 1 && (
                  <AudioGram
                    fields={fields}
                    setFields={setFields}
                    value={value}
                    setValue={setValue}
                  />
                )}
                {value == 2 && (
                  <SelectDeviceui fields={fields} setFields={setFields} />
                )}
                <Box
                  mt={6}
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    display: "flex",
                    alignItems: "center",
                    // zIndex: 1,
                    height: "8vh",
                    width: "100%",
                    backgroundColor: "white",
                    padding: "5px",
                    justifyContent: "flex-end",
                    borderTop: "1px solid #ccc",
                  }}
                >
                  {/* {value > 0 && !isUpdate && (
                                <Box mr={2}>
                                    <SubmitButton
                                        onClick={() => {
                                            setValue(value - 1);
                                        }}
                                        variant="text"
                                        type="button"
                                        sx={{
                                            minWidth: "200px",
                                            textDecoration: "underline !important",
                                        }}
                                        title="Back"
                                    />
                                </Box>
                            )} */}

                  <Box>
                    {(value == 0 ||
                      (value == 2 && isDeviceSelect) ||
                      (isUpdate && value == 1 && isAudioGranSelect)) && (
                      <>
                        {console.log(
                          "this is isDeviceSelect",
                          isDeviceSelect,
                          value
                        )}
                        <SubmitButton
                          type="submit"
                          onClick={onSubmit}
                          sx={{ minWidth: "200px" }}
                          title={value == 0 && !isUpdate ? "Next" : "Save"}
                        />
                      </>
                    )}

                    {value == 1 && isAudioGranSelect && !isUpdate && (
                      <SubmitButton
                        onClick={() => {
                          setValue(value + 1);
                        }}
                        sx={{ minWidth: "200px" }}
                        title={"Next"}
                      />
                    )}
                  </Box>
                </Box>
              </>
            }
          </Grid>
        )}
        {/* </ModuleContainer > */}
      </Box>
    </>
  );
};
export default memo(CreateUi);
