import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
  useTheme,
  Divider,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import IncreasDecreaseButton from "../../components/button/IncreaseDecreaseButton";
import BothSideSlider from "../../components/button/BothSiderSlider";
import FittingChartArea from "./FittingChartArea";
import { openModal } from "../../store/actions/modalAction";
import AdvanceFittingController from "./AdvanceFittingController";
import {
  applyFeedbackCancellation,
  applyWinNoiseBlock,
  autofitDevice,
  changeByteValue,
  changeCompression,
  changeDeviceArray,
  changeDeviceSideSelection,
  changeMasterGain,
  changeMultipleByteValue,
  changeNoiseReduction,
  changeProfileId,
  changeRemarks,
  changeSharpness,
  changeSpeechRedcution,
  changeWindNoiseReduction,
  disconnectDevice,
  readDataFromDevice,
  resetDevice,
} from "../../store/actions/fittingAction";
import { memo, useState } from "react";
import SubmitButton from "../../components/button/SubmitButton";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import {
  addPatientProfileApi,
  updatePatientProfileField,
} from "../../apis/patientprofile.api";
import { useNavigate, useParams } from "react-router-dom";
import { SNACK_BAR_VARIETNS, USER_ROLES, actions } from "../../utils/constants";
import {
  changeOriginalValueFromMediun,
  findMediun,
  mapValues,
} from "../../utils/helper";
import { GAIN_POINTS } from "../../utils/j10.helper";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";
import { closeModal } from "../../store/actions/modalAction";
import RightEar from "../../assets/images/Left (1).png";
import LeftEar from "../../assets/images/Left (2).png";
import FittingChartAreaLeft from "./FittingChartAreaLeft";
import { Slider } from "@mui/material";
import MenuItemgraphImage from "../../assets/images/MenuItemgraphImage.svg";

const StyledButton = styled(ButtonBase)(({ theme, active }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flex: 1,
  color: active ? "unset" : theme.palette.light.main,
  border: "1px solid " + theme.palette.primary.main,

  borderLeft: 0,
  borderRight: 0,
  borderBottom: active ? 0 : 1,
  // borderTopLeftRadius: theme.shape.borderRadius *2,
  // borderTopRightRadius: theme.shape.borderRadius *2,
  background: active ? theme.palette.light.main : theme.palette.primary.main,
}));
const ActionInputButtons = memo(({ title, ...rest }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{ width: "100%", borderLeft: "1px solid" + theme.palette.grey[300] }}
      pl={3}
    >
      <Typography
        variant="body1"
        lineHeight="100%"
        color="dark.main"
        fontWeight={500}
        mb={2}
      >
        {title}
      </Typography>
      <IncreasDecreaseButton {...rest} />
    </Box>
  );
});
const ActionInputSliders = ({ title, ...rest }) => {
  const theme = useTheme();
  return (
    <Box sx={{ width: "6vw" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
          // width: "100%",
          // marginBottom: "-3px",
        }}
      >
        <Typography
          variant="h6"
          display={"flex"}
          justifyContent={"center"}
          color="dark.main"
          fontWeight={500}
        >
          {title}
        </Typography>
        <Typography>{rest.value}</Typography>
      </Box>
      <Box
        mt={2}
        sx={{
          display: "flex",
          alignItems: "space-between",
          justifyContent: "center",
          flexDirection: "row",
          // width: "100%",
          // marginBottom: "-3px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }} ml={4}>
            <BothSideSlider {...rest} />
          </Box>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color="grey">
              {10}
            </Typography>
            <Typography variant="h6" color="grey">
              {-10}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const marks = [
  { value: 0, label: "Off" },
  { value: 1, label: "Low" },
  { value: 2, label: "Medium" },
  { value: 3, label: "High" },
  { value: 4, label: "Very High" },
  { value: 5, label: "Max" },
];

const CustomSlider = styled(Slider)(({ side }) => ({
  backgroundColor: "white",

  height: 0.1,
  // '& .MuiSlider-track': {
  //   border: 'black  ',
  // },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    boxShadow: "none",
    backgroundColor:
      side
        ? "rgba(238, 210, 210, 0.5 )"
        : "rgba(229, 238, 244, 0.5)",
    border: side ? "4px solid  #C24747" : "4px solid #1265A1",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    left: "calc(-50% + 4px)",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#DDDDDD",
    height: 10,
    width: 10,
    borderRadius: 20,

    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "white",
    },
  },
  "& .MuiSlider-markLabel": {
    color: "#808080",
    fontSize: "1vw",
    // fontWeight: 'bold',
    fontFamily: "League spartan",
  },
  "& .MuiSlider-markLabelActive": {
    color: "#2D3B67",
    // fontWeight: 'bold',
    fontFamily: "League spartan",
  },
}));

const CustomScrollBar = ({ children }) => {
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down(1200));
  const scrollbarStyles = {
    container: {
      height: "100%",
      width: "100%",
      overflowY: "scroll",
      overflowX: "hidden",
    },

    scrollbar: {
      /* Webkit-based browsers */
      "::-webkit-scrollbar": {
        width: "10px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f0f0f0",
        borderRadius: "6px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "red",
        borderRadius: "6px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#red",
      },
      /* Firefox */
      scrollbarWidth: "thin",
      scrollbarColor: "red",
    },
  };
  return (
    <Box style={scrollbarStyles.container}>
      <Box style={scrollbarStyles.scrollbar}>{children}</Box>
    </Box>
  );
};

const BasicFittingAreaController = ({ mutedHandle, onAutoFit }) => {
  const params = useParams();
  const theme = useTheme();
  const { user, fitting, fittingLeft } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [autoFitLeftloading, setAutoFitLeftloading] = useState(false);
  const [autoFitRightloading, setAutoFitRightloading] = useState(false);
  console.log("this page run now ");

  const navigate = useNavigate();
  const onAdvanceFittingBtnClick = () => {
    dispatch(
      openModal(
        <>
          <AdvanceFittingController fitting={fitting} />
        </>,
        "xl",
        true,
        "advance-fitting"
      )
    );
  };
  // const onResetBtnClick = () => {
  //   dispatch(resetDevice(fitting.device_side));
  //   console.log("reset", fitting.data);
  // };

  const [readLoading, setReadLoading] = useState(false);
  const [saveLoadin, setSaveLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);

  const onReadFromDeviceBtnClick = async () => {
    setReadLoading(true);
    const data = await fitting.writeFun.readData(fitting.data);
    setReadLoading(false);
    dispatch(readDataFromDevice(data, fitting.device_side));
  };
  const onSaveFitting = async (complete, callBack = () => {}, muted) => {
    if (complete === true) setCompleteLoading(true);
    else if (!muted) {
      setSaveLoading(true);
    }

    const finalFittingData = fitting.data;
    console.log("before finalFittingData", finalFittingData);
    if (fitting.device_type == DEVICES.BTE_32) {
      console.log("inside");
      finalFittingData[10] = 66;
      finalFittingData[11] = 84;
      finalFittingData[12] = 69;
      finalFittingData[13] = 51;
      finalFittingData[14] = 50;
      finalFittingData[15] = 95;
      if (fitting.device_side == LISTENING_SIDE.LEFT) {
        finalFittingData[16] = 76;
      } else {
        finalFittingData[16] = 82;
      }
      finalFittingData[17] = 0;
      finalFittingData[18] = 0;
      finalFittingData[19] = 0;
    }
    console.log("after finalFittingData", finalFittingData);
    finalFittingData[GAIN_POINTS.MASTER_GAIN] = mapValues(fitting.master_gain);
    finalFittingData[GAIN_POINTS.NOISE_REDUCTION] = mapValues(
      fitting.noise_reduction
    );
    finalFittingData[GAIN_POINTS.LOUDNESS_COMPRESSION] = mapValues(
      fitting.loudness_compression
    );
    finalFittingData[GAIN_POINTS.SELF_SPEECH_REDUCTION] = mapValues(
      fitting.self_speech_reduction
    );
    finalFittingData[GAIN_POINTS.SHARPNESS] = mapValues(fitting.sharpness);
    finalFittingData[GAIN_POINTS.WIND_NOISEREDUCTION] = mapValues(
      fitting.wind_noise_reduction
    );

    await fitting?.writeFun?.writeData?.(finalFittingData);

    const passData = {
      patient_id: params.id,
      device_id: fitting.deviceInfo?.id,
      device_name: fitting.deviceInfo?.name,
      device_type: fitting.device_type,
      device_side: fitting.device_side,
      master_gain: fitting.master_gain,
      noise_reduction: fitting.noise_reduction,
      loudness_compression: fitting.loudness_compression,
      self_speech_reduction: fitting.self_speech_reduction,
      sharpness: fitting.sharpness,
      wind_noise_reduction: fitting.wind_noise_reduction,
      fitting_data: fitting.data,
      remarks: fitting.remarks,
    };

    if (fitting.profile_id) {
      dispatch(
        callApiAction(
          async () =>
            await updatePatientProfileField({
              id: fitting.profile_id,
              ...passData,
            }),
          async (response) => {
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
            callBack();
            !muted &&
              dispatch(
                callSnackBar(
                  " Fitting Saved Successfully",
                  SNACK_BAR_VARIETNS.suceess
                )
              );
          },
          (err) => {
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          }
        )
      );
    } else
      dispatch(
        callApiAction(
          async () => await addPatientProfileApi(passData),
          async (response) => {
            dispatch(changeProfileId(response._id));
            callBack();
            !muted &&
              dispatch(
                callSnackBar(
                  " Fitting Saved Successfully",
                  SNACK_BAR_VARIETNS.suceess
                )
              );
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
          },
          (err) => {
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          }
        )
      );
  };
  const onCompeteClick = () => {
    onSaveFitting(
      true,
      () => {
        fitting.disconnectFun?.();
        dispatch(disconnectDevice(fitting.device_side));
      },
      false
    );
  };
  const [tab, setTab] = useState(0);

  ///for setting last amount
  const [edgeVal, setEdgeVal] = useState(50);
  const [Link, setLink] = useState(false);

  const [Selectitem, setSelectitem] = useState(4);
  const handleChange = (event) => {
    if (event.target.value === 1 || event.target.value === 4) {
      setSelectitem(event.target.value);
    }
  };

  const [value, setValue] = useState(2);

  const handleChangee = (event, newValue) => {
    setValue(newValue);
  };

  const frequencyGridWidth = tab != 1 ? 3 : 6;
  return (
    <Box>
      <Box maxHeight={"60vh"}>
        <Grid xs={12} md={12} container>
          <Grid md={5.7} xs={5.7}>
            <FittingChartArea
              mutedHandle={mutedHandle}
              fitting={fitting}
              edgeVal={edgeVal}
            />
          </Grid>
          <Grid md={0.6} xs={0.6}>
            <Box
              sx={{
                marginTop: "20vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Select value={10} sx={{ height: "5vh", width: "5vw" }}>
                  <MenuItem value={10}>
                    <img src={MenuItemgraphImage} alt="SPEAKERIMAGE" />
                  </MenuItem>
                </Select>
              </Box> */}
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mr={1}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        borderRadius: "86px",
                        width: "1.5vw",
                        height: "4px",
                        background: "#4A4A4A",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "League spartan",
                        color: "#808080",
                        fontStyle: "normal",
                        fontWeight: "400px",
                      }}
                    >
                      50dB
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mr={1}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        borderRadius: "86px",
                        width: "1.5vw",
                        height: "4px",
                        background: "#969696",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "League spartan",
                        color: "#808080",
                        fontStyle: "normal",
                        fontWeight: "400px",
                      }}
                    >
                      65dB
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mr={1}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        borderRadius: "86px",
                        width: "1.5vw",
                        height: "4px",
                        background: "#CCCCCC",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "League spartan",
                        color: "#808080",
                        fontStyle: "normal",
                        fontWeight: "400px",
                      }}
                    >
                      80dB
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={5.7} md={5.7}>
            <FittingChartArea
              mutedHandle={mutedHandle}
              fitting={fittingLeft}
              edgeVal={edgeVal}
            />
          </Grid>
        </Grid>
        <Grid container md={12} display={"flex"} justifyContent={"center"}>
          <Grid mt={1}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "League spartan",
                color: "#808080",
              }}
            >
              Fitting Formula
            </Typography>
          </Grid>
          <Grid ml={1}>
            <Select
              sx={{
                height: "5vh",
                width: "9vw",
                fontSize: "16px",
                fontFamily: "League spartan",
                color: "#4D4D4D",
              }}
              disabled={fitting.read_only || fittingLeft.read_only}
              value={Selectitem}
              onChange={handleChange}
            >
              <MenuItem
                sx={{
                  fontSize: "16px",
                  fontFamily: "League spartan",
                  color: "#4D4D4D",
                }}
                value={1}
              >
                DSL v5
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "16px",
                  fontFamily: "League spartan",
                  color: "#4D4D4D",
                }}
                value={2}
              >
                NAL
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "16px",
                  fontFamily: "League spartan",
                  color: "#4D4D4D",
                }}
                value={3}
              >
                1/3 Gain
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "16px",
                  fontFamily: "League spartan",
                  color: "#4D4D4D",
                }}
                value={4}
              >
                Fit 6
              </MenuItem>
            </Select>
          </Grid>

          {/* </FormControl> */}
        </Grid>
      </Box>
      <Box
        ml={6}
        mt={2}
        sx={{ height: "40vh", scrollbarWidth: "none" }}
        overflow={"auto"}
      >
        <Grid container xs={12} md={12}>
          <Grid
            xs={5.4}
            md={5.4}
            sx={{ height: "38vh", scrollbarWidth: "none" }}
          >
            {" "}
            {fitting.connected && (
              <Grid container xs={12} md={12}>
                <Grid xs={3} md={3}>
                  {/* {!autoFitRight ? ( */}
                  {!fitting.read_only && (
                    <SubmitButton
                      loading={autoFitRightloading}
                      onClick={() => {
                        setAutoFitRightloading(true);
                        onAutoFit(fitting, setAutoFitRightloading, Selectitem);

                        if (Link) {
                          setAutoFitLeftloading(true);
                          onAutoFit(
                            fittingLeft,
                            setAutoFitLeftloading,
                            Selectitem
                          );
                        }
                      }}
                      variant="outlined"
                      sx={{
                        fontSize: "18px",
                        fontFamily: "League spartan",
                        fontWeight: 500,
                        padding: "8px, 4px, 8px, 4px",
                        height: "6vh",
                        width: "7vw",
                        border: "1px solid #DDDDDD",
                      }}
                      title={<Typography variant="h6">Auto Fit</Typography>}
                    />
                  )}
                  {/* <Typography variant="h6">Auto Fit</Typography> */}
                  {/* ) : (
                <Button 
                  onClick={() => {
                    dispatch(
                      autofitDevice(
                        params.id,
                        () => {
                          dispatch(
                            callSnackBar(
                              "Device fitted as per patient audiogram.",
                              SNACK_BAR_VARIETNS.suceess
                            )
                          );
                        },
                        (err) => {
                          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                        },
                        fitting
                      )
                    );
                    setAutoFitRight(true);
                  }}
                  variant="outlined"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "League spartan",
                    fontWeight: 500,
                    padding: "8px, 4px, 8px, 4px",
                    height: "6vh",
                    width: "7vw",
                    border: "1px solid #2D3B67",
                    backgroundColor: "#EDF0F7",
                  }}
                >
                  Auto Fit{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99992 14.6668C11.6818 14.6668 14.6666 11.6821 14.6666 8.00016C14.6666 4.31826 11.6818 1.3335 7.99992 1.3335C4.31802 1.3335 1.33325 4.31826 1.33325 8.00016C1.33325 11.6821 4.31802 14.6668 7.99992 14.6668Z"
                      fill="#39AC8A"
                    />
                    <path
                      d="M5 8L7 10L11 6"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              )} */}
                  {/* <Grid > */}
                  <Box
                    mt={2}
                    // mb={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "7vw",
                      justifyContent: "center",
                      border: "1px solid #DDDDDD",
                      borderRadius: theme.shape.borderRadius * 0.5,
                    }}
                    p={2}
                  >
                    <ActionInputSliders
                      title="Master Gain"
                      disabled={fitting.read_only}
                      min={-10}
                      max={10}
                      side={LISTENING_SIDE.RIGHT}
                      value={fitting.master_gain}
                      baseVal={0}
                      onChange={(x) => {
                        dispatch(changeMasterGain(x, fitting.listening_side));
                        if (Link) {
                          dispatch(
                            changeMasterGain(
                              fittingLeft.master_gain + x - fitting.master_gain,
                              fittingLeft.listening_side
                            )
                          );
                        }
                      }}
                    />
                  </Box>
                  {/* </Grid> */}
                </Grid>

                <Grid md={9} xs={9}>
                  {/* <Grid mr={1} pl={3} sx={{ width: "28vw", border: "1px solid #DDDDDD", borderRadius: "4px",minHeight: "10vh" }}> */}
                  <Box
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Noise Reduction
                      </Typography>
                      <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <CustomSlider
                          side={LISTENING_SIDE.LEFT}
                          disabled={fitting.read_only}
                          track={false}
                          value={fitting?.noise_reduction}
                          min={0}
                          max={5}
                          step={1}
                          marks={marks}
                          onChange={(x) => {
                            dispatch(
                              changeNoiseReduction(
                                x.target.value,
                                fitting?.listening_side
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeNoiseReduction(
                                  fittingLeft.noise_reduction +
                                    x.target.value -
                                    fitting.noise_reduction,
                                  fittingLeft?.listening_side
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* </Grid> */}
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Self Speech Reduction
                      </Typography>
                      <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <CustomSlider
                          side={LISTENING_SIDE.LEFT}
                          disabled={fitting.read_only}
                          track={false}
                          value={fitting.self_speech_reduction}
                          min={0}
                          max={5}
                          step={1}
                          marks={marks}
                          onChange={(x) => {
                            dispatch(
                              changeSpeechRedcution(
                                x.target.value,
                                fitting?.listening_side
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeSpeechRedcution(
                                  fittingLeft.self_speech_reduction +
                                    x.target.value -
                                    fitting.self_speech_reduction,
                                  fittingLeft?.listening_side
                                )
                              );
                            }
                            console.log(
                              "this is fitting value",
                              fitting.self_speech_reduction
                            );
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Loudness Compression
                      </Typography>
                      <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <CustomSlider
                          side={LISTENING_SIDE.LEFT}
                          disabled={fitting.read_only}
                          track={false}
                          value={fitting.loudness_compression}
                          min={0}
                          max={5}
                          step={1}
                          marks={marks}
                          onChange={(x) => {
                            dispatch(
                              changeCompression(
                                x.target.value,
                                fitting?.listening_side
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeCompression(
                                  fittingLeft.loudness_compression +
                                    x.target.value -
                                    fitting.loudness_compression,
                                  fittingLeft?.listening_side
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Sharpness
                      </Typography>
                      <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <CustomSlider
                          side={LISTENING_SIDE.LEFT}
                          disabled={fitting.read_only}
                          track={false}
                          value={fitting.sharpness}
                          min={0}
                          max={5}
                          step={1}
                          marks={marks}
                          onChange={(x) => {
                            dispatch(
                              changeSharpness(
                                x.target.value,
                                fitting?.listening_side
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeSharpness(
                                  fittingLeft.sharpness +
                                    x.target.value -
                                    fitting.sharpness,
                                  fittingLeft?.listening_side
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Wind Noise Reduction
                      </Typography>
                      <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <CustomSlider
                          side={LISTENING_SIDE.LEFT}
                          disabled={fitting.read_only}
                          track={false}
                          value={fitting.wind_noise_reduction}
                          min={0}
                          max={5}
                          step={1}
                          marks={marks}
                          onChange={(x) => {
                            dispatch(
                              changeWindNoiseReduction(
                                x.target.value,
                                fitting?.listening_side
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeWindNoiseReduction(
                                  fittingLeft.wind_noise_reduction +
                                    x.target.value -
                                    fitting.wind_noise_reduction,
                                  fittingLeft?.listening_side
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid
            xs={1}
            md={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {fitting.connected && fittingLeft.connected && (
              <Box
                pl={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!Link ? (
                  <button
                    style={{
                      border: "1px solid #DDDDDD",
                      borderRadius: "8px",
                      width: "4vw",
                      backgroundColor: "#FFFFFF",
                      height: "5vh",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setLink(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="24"
                      viewBox="0 0 33 24"
                      fill="none"
                    >
                      <path
                        d="M11.5 17H9.5C6.73858 17 4.5 14.7614 4.5 12C4.5 9.23858 6.73858 7 9.5 7H11.5M21.5 17H23.5C26.2614 17 28.5 14.7614 28.5 12C28.5 9.23858 26.2614 7 23.5 7H21.5"
                        stroke="#CCCCCC"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setLink(false);
                    }}
                    style={{
                      border: "1px solid #2D3B67",
                      borderRadius: "8px",
                      width: "4vw",
                      backgroundColor: "#EDF0F7",
                      height: "5vh",
                      cursor: "pointer",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="24"
                      viewBox="0 0 32 24"
                      fill="none"
                    >
                      <path
                        d="M13 17H11C8.23858 17 6 14.7614 6 12C6 9.23858 8.23858 7 11 7H13M19 17H21C23.7614 17 26 14.7614 26 12C26 9.23858 23.7614 7 21 7H19M11 12L21 12"
                        stroke="#2D3B67"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </Box>
            )}
          </Grid>
          {fittingLeft.connected && (
            <Grid
              xs={5.4}
              md={5.4}
              sx={{ height: "45vh", scrollbarWidth: "none" }}
            >
              <Grid container md={12} xs={12}>
                <Grid xs={3} md={3}>
                  {/* {!autoFitLeft ? ( */}
                  {!fittingLeft.read_only && (
                    <SubmitButton
                      loading={autoFitLeftloading}
                      onClick={() => {
                        setAutoFitLeftloading(true);
                        onAutoFit(
                          fittingLeft,
                          setAutoFitLeftloading,
                          Selectitem
                        );

                        if (Link) {
                          setAutoFitRightloading(true);
                          onAutoFit(
                            fitting,
                            setAutoFitRightloading,
                            Selectitem
                          );
                        }
                      }}
                      variant="outlined"
                      sx={{
                        fontSize: "18px",
                        fontFamily: "League spartan",
                        fontWeight: 500,
                        padding: "8px, 4px, 8px, 4px",
                        height: "6vh",
                        width: "7vw",
                        border: "1px solid #DDDDDD",
                      }}
                      title={<Typography variant="h6">Auto Fit</Typography>}
                    />
                  )}
                  {/* ) : (
                <Button
                  onClick={() => {
                    dispatch(
                      autofitDevice(
                        params.id,
                        () => {
                          dispatch(
                            callSnackBar(
                              "Device fitted as per patient audiogram.",
                              SNACK_BAR_VARIETNS.suceess
                            )
                          );
                        },
                        (err) => {
                          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                        },
                        fittingLeft
                      )
                    );
                    setAutoFitLeft(true);
                  }}
                  variant="outlined"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "League spartan",
                    fontWeight: 500,
                    padding: "8px, 4px, 8px, 4px",
                    height: "6vh",
                    width: "7vw",
                    border: "1px solid #2D3B67",
                    backgroundColor: "#EDF0F7",
                  }}
                >
                  Auto Fit{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99992 14.6668C11.6818 14.6668 14.6666 11.6821 14.6666 8.00016C14.6666 4.31826 11.6818 1.3335 7.99992 1.3335C4.31802 1.3335 1.33325 4.31826 1.33325 8.00016C1.33325 11.6821 4.31802 14.6668 7.99992 14.6668Z"
                      fill="#39AC8A"
                    />
                    <path
                      d="M5 8L7 10L11 6"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              )} */}
                  {/* <Grid > */}
                  <Box
                    mt={2}
                    // mb={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "7vw",
                      justifyContent: "center",
                      border: "1px solid #DDDDDD",
                      borderRadius: theme.shape.borderRadius * 0.5,
                    }}
                    p={2}
                  >
                    <ActionInputSliders
                      title="Master Gain"
                      disabled={fittingLeft.read_only}
                      min={-10}
                      max={10}
                      value={fittingLeft.master_gain}
                      baseVal={0}
                      onChange={(x) => {
                        dispatch(
                          changeMasterGain(x, fittingLeft.listening_side)
                        );

                        if (Link) {
                          dispatch(
                            changeMasterGain(
                              fitting.master_gain + x - fittingLeft.master_gain,
                              fitting.listening_side
                            )
                          );
                        }
                      }}
                    />
                  </Box>
                  {/* </Grid> */}
                </Grid>

                <Grid xs={9} md={9}>
                  {/* <Grid mr={1} pl={3} sx={{ width: "28vw", border: "1px solid #DDDDDD", borderRadius: "4px",minHeight: "10vh" }}> */}
                  <Box
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "12px",
                            fontFamily: "League spartan",
                            fontWeight: "400",
                          }}
                        >
                          Noise Reduction
                        </Typography>
                        <CustomSlider
                          disabled={fittingLeft.read_only}
                          track={false}
                          value={fittingLeft.noise_reduction}
                          min={0}
                          max={5}
                          step={1}
                          marks={marks}
                          baseVal={0}
                          onChange={(x) => {
                            dispatch(
                              changeNoiseReduction(
                                x.target.value,
                                fittingLeft?.listening_side
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeNoiseReduction(
                                  fitting.noise_reduction +
                                    x.target.value -
                                    fittingLeft.noise_reduction,
                                  fitting?.listening_side
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* </Grid> */}
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Self Speech Reduction
                      </Typography>
                      <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <CustomSlider
                          disabled={fittingLeft.read_only}
                          track={false}
                          value={fittingLeft.self_speech_reduction}
                          min={0}
                          max={5}
                          step={1}
                          marks={marks}
                          onChange={(x) => {
                            dispatch(
                              changeSpeechRedcution(
                                x.target.value,
                                fittingLeft?.listening_side
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeSpeechRedcution(
                                  fitting.self_speech_reduction +
                                    x.target.value -
                                    fittingLeft.self_speech_reduction,
                                  fitting?.listening_side
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Loudness Compression
                      </Typography>
                      <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <CustomSlider
                          disabled={fittingLeft.read_only}
                          track={false}
                          value={fittingLeft.loudness_compression}
                          min={0}
                          max={5}
                          step={1}
                          marks={marks}
                          onChange={(x) => {
                            dispatch(
                              changeCompression(
                                x.target.value,
                                fittingLeft?.listening_side
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeCompression(
                                  fitting.loudness_compression +
                                    x.target.value -
                                    fittingLeft.loudness_compression,
                                  fitting?.listening_side
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Sharpness
                      </Typography>
                      <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <CustomSlider
                          disabled={fittingLeft.read_only}
                          track={false}
                          value={fittingLeft.sharpness}
                          min={0}
                          max={5}
                          step={1}
                          marks={marks}
                          onChange={(x) => {
                            dispatch(
                              changeSharpness(
                                x.target.value,
                                fittingLeft?.listening_side
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeSharpness(
                                  fitting.sharpness +
                                    x.target.value -
                                    fittingLeft.sharpness,
                                  fitting?.listening_side
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Wind Noise Reduction
                      </Typography>
                      <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <CustomSlider
                          disabled={fittingLeft.read_only}
                          track={false}
                          value={fittingLeft.wind_noise_reduction}
                          min={0}
                          max={5}
                          step={1}
                          marks={marks}
                          onChange={(x) => {
                            dispatch(
                              changeWindNoiseReduction(
                                x.target.value,
                                fittingLeft?.listening_side
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeWindNoiseReduction(
                                  fitting.wind_noise_reduction +
                                    x.target.value -
                                    fittingLeft.wind_noise_reduction,
                                  fitting?.listening_side
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default BasicFittingAreaController;

// IGi=0 for Hi<20dB HL
// IGi=0.6(Hi-20) for 20≤Hi≤60dB HL
// IGi=0.8Hi-23 for Hi > 60dB HL
